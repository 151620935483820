import { template as template_cbb4db8627454bd98e311247d6b7aea1 } from "@ember/template-compiler";
const FKText = template_cbb4db8627454bd98e311247d6b7aea1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
