import { template as template_1e1c946bd2c0406f97b2509597bfd331 } from "@ember/template-compiler";
const WelcomeHeader = template_1e1c946bd2c0406f97b2509597bfd331(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
