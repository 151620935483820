import { template as template_95aa55f42ed8480685b369985980c9bf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_95aa55f42ed8480685b369985980c9bf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
