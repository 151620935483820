import { template as template_1beba1cdb33e4b4b86cb9b08614c73b0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1beba1cdb33e4b4b86cb9b08614c73b0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
