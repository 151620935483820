import { template as template_95b906c604ae43f694b0e3e7895f519d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_95b906c604ae43f694b0e3e7895f519d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
